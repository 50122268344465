/**
 * User Registration Form
 */
import React from "react"
import queryString from "query-string"
import { RegisterForm } from "../../components/Forms/RegisterForm"

function Register({ location }) {
  function getInviteId() {
    const params = queryString.parse(location.search)
    return params.invitation
  }

  return <RegisterForm invitationId={getInviteId()} />
}

export default Register
